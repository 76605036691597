import { reactive, toRefs } from "vue";

import { GrowthTrends, PlatesStreakingPattern, PrimaryProtocol, SecondaryProtocol, SamplePerDay, Settings, SlidesStreakingPattern, LineConfig, DailyData, WeightedDayTimes, ConfiguratorAdditionalItems, FteHours } from "@/types";

const state = reactive({
  userRole: "",
  settings: {
    broths: {
      timeInSeconds: 70
    },
    radian: {
      timeInSeconds: 35
    },
    colibri: {
      IDTimeInSeconds: 60,
      ASTTimeInSeconds: 120,
      ASTIDTimeInSeconds: 175,
      ASTIDPurityTimeInSeconds: 240,
      ASTPurityTimeInSeconds: 180
    },
    flo: {
      platesPerHour: 550
    },
    incubator: {
      loadingPlatesPerHour: 125,
      recordingPlatesPerHour: 100,
      unloadingSinglePlatesPerHour: 180,
      unloadingMultiplePlatesPerHour: 250,
      incubatorSafetyMargin: 20,
      singleIncubator: true,
    },
    plates: {
      streakingPatterns: [
        {
          pattern: "1",
          timeInSeconds: 25
        },
        {
          pattern: "2",
          timeInSeconds: 35
        },
        {
          pattern: "3",
          timeInSeconds: 40
        },
        {
          pattern: "4",
          timeInSeconds: 45
        }
      ] as PlatesStreakingPattern[]
    },
    slides: {
      streakingPatterns: [
        {
          pattern: "slide_only",
          timeInSeconds: 60
        },
        {
          pattern: "slide_and_other",
          timeInSeconds: 30
        }
      ] as SlidesStreakingPattern[]
    },
    fte: {
      manualStreakingSecondsPerPlate: 60,
      screeningSecondsPerPlate: 10,
      readingSecondsPerPlate: 30,
      pickingSecondsPerPlate: 60,
      pickingSecondsPerSubcultures: 60,
      productivitySecondsPerPlate: 15,
      phenomatrixSecondsPer30Plates: 10,
      waspMinutesPerHour: 5,
      wasplabMinutesPerIncubatorPerDay: 5,
      radianMinutesPerDay: 20,
      colibriMinutesPerDay: 5,
      floMinutesPerWorkingHour: 5
    }
  } as Settings,
  samplesPerDay: [
    {
      dayOfWeek: 1,
      samples: 0
    },
    {
      dayOfWeek: 2,
      samples: 0
    },
    {
      dayOfWeek: 3,
      samples: 0
    },
    {
      dayOfWeek: 4,
      samples: 0
    },
    {
      dayOfWeek: 5,
      samples: 0
    },
    {
      dayOfWeek: 6,
      samples: 0
    },
    {
      dayOfWeek: 0,
      samples: 0
    },
  ] as SamplePerDay[],
  growthTrends: {
    startYear: new Date().getFullYear(),
    endYear: new Date().getFullYear(),
    yearOfReference: new Date().getFullYear(),
    growthTrends: [0, 0, 0]
  } as GrowthTrends,
  fteHours: {
    wasp: {
      start: 8,
      end: 18
    },
    manualStreaking: {
      start: 8,
      end: 18
    },
    screening: {
      start: 8,
      end: 18
    },
    reading: {
      start: 8,
      end: 18
    },
    manualPicking: {
      start: 8,
      end: 18
    },
    colibri: {
      start: 8,
      end: 18
    }
  } as FteHours,
  primaryProtocols: [] as PrimaryProtocol[],
  secondaryProtocols: [] as SecondaryProtocol[],
  weightedDayTimes: [] as WeightedDayTimes[],
  weightedDayTimesPerProtocol: [] as WeightedDayTimes[],
  dailyData: [] as DailyData[],
  lines: [] as LineConfig[],
  colibriTime: 0 as number,
  colibriLoad: 0 as number,
  configuratorAdditionalItems: {
    colibri: 0,
    flo: 0,
    manualStation: 0,
    pickingStation: 0,
    readingStation: 0
  } as ConfiguratorAdditionalItems,
  users: [] as { id: string; email: string; role: string; locked: boolean; }[],
  user: {
    id: "",
    email: "",
    locked: false,
    role: ""
  } as { id: string; email: string; role: string; locked: boolean; },
  archive: [] as { id: string; userId: string; owner: string; name: string; laboratory: string; country: string; date: Date; distributor: string; data: string; }[]
});

export default function appStorage() {
  const baseUrl = "https://waspsizingtool.copangroup.com/api";

  // SERVER
  const login = async (params: { email: string, password: string; }) => {
    try {
      const res = await fetch(`${baseUrl}/login`, {
        method: "POST",
        headers: {
          'Content-Type': "application/json"
        },
        body: JSON.stringify(params)
      });
      const resBody = await res.json();
      localStorage.setItem("session", resBody.sessionId);
      localStorage.setItem("role", resBody.role);
      location.href = "/";
    } catch {
      localStorage.removeItem("session");
      localStorage.removeItem("role");
    }
  };

  // SERVER
  const getUser = async () => {
    const res = await fetch(`${baseUrl}/get-users?sessionId=${localStorage.getItem("session")}`, {
      method: "GET",
      headers: {
        'Content-Type': "application/json"
      }
    });
    state.user = await res.json();
  }

  const getUsers = async () => {
    const res = await fetch(`${baseUrl}/get-users`, {
      method: "GET",
      headers: {
        'Content-Type': "application/json"
      }
    });
    state.users = await res.json();
  }

  // SERVER
  const addUser = async (params: { email: string; password: string; role: "ADMIN" | "USER"; }) => {
    await fetch(`${baseUrl}/add-user`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json"
      },
      body: JSON.stringify({
        email: params.email,
        password: params.password,
        role: params.role
      })
    });
    await getUsers();
  }
  
  // SERVER
  const lockUser = async (params: { id: string }) => {
    const res = await fetch(`${baseUrl}/lock-user?id=${params.id}`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json"
      }
    });
    if(res.ok) {
      await getUsers();
    }
  }
  
  // SERVER
  const unlockUser = async (params: { id: string }) => {
    const res = await fetch(`${baseUrl}/unlock-user?id=${params.id}`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json"
      }
    });
    if(res.ok) {
      await getUsers();
    }
  }
  
  // SERVER
  const getArchive = async () => {
    if(state.user.id == "") {
      await getUser();
    }
    if(state.users.length == 0) {
      await getUsers();
    }
    const res = await fetch(`${baseUrl}/configs?sessionId=${localStorage.getItem("session")}`, {
      method: "GET",
      headers: {
        'Content-Type': "application/json"
      }
    });
    state.archive = await res.json();
  }
  
  // SERVER
  const archiveConfig = async (params: { name: string; laboratory: string; country: string; distributor: string; }) => {
    const currentState = JSON.parse(JSON.stringify(state));
    currentState.archive = [];
    currentState.users = [];
    const res = await fetch(`${baseUrl}/save-config?sessionId=${localStorage.getItem("session")}`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json"
      },
      body: JSON.stringify({
        name: params.name,
        laboratory: params.laboratory,
        country: params.country,
        distributor: params.distributor,
        data: JSON.stringify(currentState)
      })
    });
    if(res.ok) {
      await getArchive();
    }
  }
  
  // SERVER
  const openArchive = async (params: { id: string }) => {
    const archive = state.archive.find((x) => x.id == params.id);
    if(archive) {
      const data = JSON.parse(archive.data);
      state.settings = data.settings;
      state.samplesPerDay = data.samplesPerDay;
      state.growthTrends = data.growthTrends;
      state.fteHours = data.fteHours;
      state.primaryProtocols = data.primaryProtocols;
      for(const p of state.primaryProtocols) {
        p.negativityRate = Number(p.negativityRate.toFixed(2));
        p.idPercentage = Number(p.idPercentage.toFixed(2));
        p.astPercentage = Number(p.astPercentage.toFixed(2));
        p.astIdPercentage = Number(p.astIdPercentage.toFixed(2));
        p.radianPercentage = Number(p.radianPercentage.toFixed(2));
        p.totalPercentage = Number(p.totalPercentage.toFixed(2));
        const fixedVolumes = [];
        for(const v of p.volumes) {
          fixedVolumes.push(Number(v.toFixed(0)));
        }
        p.volumes = fixedVolumes;
      }
      state.secondaryProtocols = data.secondaryProtocols;
      state.weightedDayTimes = data.weightedDayTimes;
      state.weightedDayTimesPerProtocol = data.weightedDayTimesPerProtocol;
      state.dailyData = data.dailyData;
      state.lines = data.lines;
      state.colibriTime = data.colibriTime;
      state.configuratorAdditionalItems = data.configuratorAdditionalItems;
    }
  }

  const deleteArchive = async (params: { id: string }) => {
    await fetch(`${baseUrl}/delete-config?id=${params.id}&?sessionId=${localStorage.getItem("session")}`, {
      method: "GET",
      headers: {
        'Content-Type': "application/json"
      }
    });
    await getArchive();
  }

  return {
    ...toRefs(state),
    login,
    getUser,
    getUsers,
    addUser,
    lockUser,
    unlockUser,
    getArchive,
    archiveConfig,
    openArchive,
    deleteArchive
  }
}