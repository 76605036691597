import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "input" */ '../views/InputView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue')
  },
  {
    path: '/configurator',
    name: 'configurator',
    component: () => import(/* webpackChunkName: "configurator" */ '../views/ConfiguratorView.vue')
  },
  {
    path: '/output',
    name: 'output',
    component: () => import(/* webpackChunkName: "output" */ '../views/OutputView.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/UsersView.vue')
  },
  {
    path: '/archive',
    name: 'archive',
    component: () => import(/* webpackChunkName: "users" */ '../views/ArchiveView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  if(to.name !== 'login') {
    const baseUrl = "https://waspsizingtool.copangroup.com/api";
    try {
      const res = await fetch(`${baseUrl}/session?id=${localStorage.getItem("session")}`, {
        method: "GET",
        headers: {
          'Content-Type': "application/json"
        }
      });
      if(!res.ok) {
        return { name: 'login' }
      }
    } catch {
      return { name: 'login' }
    }
  }
})

export default router
